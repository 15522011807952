import React from "react";
import Header from "../components/Header";
import { Link } from "gatsby";
import { FaPaperPlane } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../components/banner.module.scss";

const Success = () => {
  return (
    <div>
      <Header title="" description="" />
      <div className={styles.bannerContainer}>
        <StaticImage
          src="../images/כניסה-למרפאת-השיניים-בירושלים.png"
          alt="לוגו מרפאת שיניים"
          placeholder="blurred"
          loading={"eager"}
          height={490}
          transformOptions={{ fit: "cover" }}
          className={styles.bannerBackground}
        />
        <div className={styles.bannerContentContainer}>
          <div>
            <h1 className={styles.drTitle}>קיבלתי את ההודעה</h1>
            <h1 className={styles.drTitle}>אחזור אליך בהקדם האפשרי :)</h1>

            <Link
              to="/"
              className={styles.leaveDetailsLink}
              activeClassName={styles.leaveDetailsLink}
            >
              חזור
            </Link>
          </div>
          <StaticImage
            // src="../images/רופאת-שיניים-בירושלים.png" // TODO different image for desktop and tablet
            src="../images/רופאת-שיניים-בירושלים-דר-אוריה-פינטו.png"
            alt="ד״ר אוריה פינטו | רופאת שיניים"
            placeholder="blurred"
            loading={"eager"}
            height={445}
            width={396}
            transformOptions={{ fit: "inside" }}
            className={styles.drOriaImage}
          />
        </div>
      </div>
    </div>
  );
};

export default Success;
